.App{
  text-align: center;
}
*
{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.fade-in-section {
  opacity: 0;
  transform: translateY(20vh);
  visibility: hidden;
  transition: opacity 0.2s ease-out, transform 0.6s ease-out;
  will-change: opacity, visibility;
}
.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

.App-logo {
  height: 500px;
  pointer-events: none;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {

    animation: App-logo-spin 1.9s;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
#loadingpage{
  display: flex!important;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: black
}
.btn{
  border-radius: 72px;
  padding: 15px 25px;
  margin-right: 10px;
  font-weight: 700;
  font-size: 18px;
  line-height: 120%;
  background: linear-gradient(-210deg,#00c1f9,#f729e7,#ffd400);
  background-size: 400%!important;
  border: none;
  -webkit-animation: Gradient 5s ease infinite!important;
  animation: Gradient 5s ease infinite!important;
}
@import url('https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700,800,900&display=swap');

@font-face{
  font-family: 'druk';
  src:url('Druk\ Wide\ Bold.ttf')

}
@font-face{
  font-family: 'League Spartan';
  src:url('LeagueSpartan-Bold.otf')

}
@font-face{
  font-family: 'ITC Avante Garde Gothic Bold';
  src:url('ITC\ Avant\ Garde\ Gothic\ Bold.otf')

}
.home{
  background-color: white;
  margin-top: 100px;
  height:auto;
  width: 100vw;

}
.home h1{
  font-family: 'League Spartan';
  font-weight: 500;
  letter-spacing: .05em;
  text-transform: none;
  font-size: 50px;
  color:#333333;
}
.home h2{
  font-size: 30px;
  color:#333333;
}
.enterbtn{
  height: 60px;
  width: 400px;
  background-color: #333333;
  color: white;
  padding: 15px 25px;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 19px;
  line-height: 120%;
  border-radius: 10px;
  border: none;
  
}
.col-section ul li img{
  height: 30px;
  width: 30px;
}
#phantomimg img{
  border-radius: 50px;
}
#phantomimg{
  width: 35vw;
}
#abtphantom{
  color:#333333;
  width: 65vw;
  font-size: 27px;
  align-items: center;
  display: flex;
  padding:29px;
}

.part2{
  background-color: white;
  color: #333333;
  height: 70vh;
  width: 100vw;
  display: flex;
  align-items: center;
}
.part2 h4{
  font-size: 30px;
  color: #333333;
  padding:30px
}
.part2 h3{
  font-size: 35px;
  color: #333333;
}

#access{
  font-size: 35px;
  color: lightgreen;
  font-family: 'League Spartan';
  letter-spacing: 1.4px;
  background-color: black;
  opacity: 90%;
  padding:10px;
  margin-left:150px;
  margin-right:150px;
}


.mission-section{
  width: 100vw;
  /* height: 100vh; */
  z-index: 99;
  min-height: calc(200vh - 160px);
  background-color: black;
  /* background-image: url("./images/dardan.png");
  background-attachment: fixed; */
  background-position: center top;
  padding: 20px;
}
.part5 img{
  height: 50vh;
}
.part5{
  font-size: 25px;
  color:#333333;
  height: 60vh;
  padding: 30px;
  margin-top: 30px;
}

.part5 .words{
  font-size: 25px;
  padding: 20px;
  color:#333333;
  display: flex;
  align-items: center;
}
.halfimg img{
  width: 25vw;
  height: auto;
}

.section{
  background-color: black;
  width: 100vw;
  margin-top: 60px;
  padding: 20px;
  z-index: 99;
  min-height: calc(200vh - 160px);
}

.section h1{
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #39ff14;
    -webkit-text-fill-color: transparent;
    font-size: 7.5vw!important;
    padding-bottom: 20px;
}
.mission-section h1{
  margin-top: 100px;
  color:#39ff14;
  -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #39ff14;
    -webkit-text-fill-color: transparent;
  text-align: center;
  letter-spacing: 5px;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.full-row-section{
  
  height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.full-row-section h1{
  
  font-size: 50px;
}
.row-section{
  height: 50vh;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.row-section img{
  height: 500px;
}
.row-section h4{
  font-size: 25px!important;
  line-height: 2.3em;
  font-family: 'League Spartan';
  color:black;
  width: 50vw;
}
span{
  color: #00A36C;
}
.col-section{
  height: 90vh;
  display: flex;
  flex-direction:column;
  align-items: center;
  margin-top: 100px;
  margin-bottom: 40px;
}
.col-section ul{
  list-style-type: none;
}
.col-section h4{
  font-family: 'League Spartan';
  line-height: 2.3em;
  font-size: 40px;
  width: 100%;
  text-align: center;
  color:#333333;
}
.col-section span{
  border-bottom: 5px dashed #00A36C;
  color:#00A36C;
}

.col-section li{
  font-size: 35px;
  text-align: left;
  font-family: 'ITC Avante Garde Gothic Bold';
  margin-bottom: 15px;
  color: #333333;
  
}
.col-1{
  vertical-align: text-top;
}
.col-2{
  background-color: #00A36C;
  height: 50vh;
  width:50vw;

}


nav {
  
  display: flex;
  background-color: white;
  justify-content: space-evenly;
  align-items: center;
  height: 90px;
  width: 100vw;
  top:0;
  z-index: 99;
  position: fixed;
  background: transparent;
}
ul img{
  height:15px;
  width: 15px;
  margin-right: 10px;
}

@keyframes fadeIn {
  0% {
    opacity: 0.0;
  }
  100% {
    opacity: 2.0;
  }
}

nav li a{
  opacity: 0;
  animation: fadeIn 1.6s 1;
  animation-fill-mode: forwards;
  color:White;
  text-decoration: none;
  font-weight: 800;
  font-size: 14px;
  margin-left:12px;
  margin-right:12px;
  letter-spacing: 3px;
  text-transform: uppercase;
  
}


li:nth-child(5n+1) {
  animation-delay: 1.5s;
}
.option :hover {
  color: white;
  border-bottom: 4px solid 	#39ff14;
}


  
.navlogo{
  height: 105px;
  width: 105px;
  /* flex-grow: 1;  */

}
  
  
.logo-nav {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  
}
.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile-menu {
  display: none;
}
.mobile-option {
  display: none;

}

.nav-options {
  display:flex;
  margin-bottom: 0px;

  list-style-type: none;
}

.fade-in{
  opacity: 0;
  transform: translateY(310vh);
  visibility: hidden;
  transition: opacity 0.6s ease-out, transform 1.9s ease-out;
  will-change: opacity, visibility;
}
.fade-in.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}


/* footer */
.footer{
  height: 150px;
  width: 100vw;
  padding: 5px;
  z-index: 200;
  background-color: black;
  position: relative;
  left: 0;
  bottom: 0;
  border-top:10px solid black;
}
#icons img{
  height: 50px;
  width: 50px;
  margin: 20px
}
#icons img:hover {
  height: 60px;
  width: 60px;
  background: linear-gradient(-210deg,#2E2EFF,#f729e7,#ffd400);
  border-radius: 10px;
}

/* web3 education */
.ed{
  background-color:#00A36C;
}
.term{
  text-align: left;
  height: 220px;
  padding-left: 195px;
  padding-right: 195px;
  padding-top: 60px;
  padding-top: 30px;
  background-color: white;
  margin-top: 0px;
}
.term h2{
  color:#00A36C;
  font-size: 35px;
  letter-spacing: 1.5px;
}

.term h3{
  color:	#656565;
  margin-top: 11px;
  padding-bottom: 10px;
  font-size: 22px;


}

#idk{
  background-color: white;
}
.parent{
  position:relative;
  top: -50px;
}

/* meet the team */
.section #team{
  z-index: 100;
}
.scroll-button {
  position: fixed;
  bottom: 24%;
  right: 2%;
  z-index: 100;
  height:40px;
  width: 40px
}

.teammember{
  padding: 10px;
  background-color: #000; 
  width: 90vw;
  height: 90vh;
  display: flex;
  flex-direction: row
}
.teammember .image{
  width: 50%;
  padding: 20px; /* Scale the image based on the viewport width */ /* Maximum size of the image */
}
.teammember img{
  width: 100%;
  height:90vh;
  object-fit: cover;
  object-position: 0% 0%;
}
.teammember .abt{
  width: 50%;
  padding: 50px;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  
}
.teammember h2{
  font-size: 40px;
  letter-spacing: 1.5px;
  padding: 10px;
  align-items: center;
  color: black;
  transition: all 0.2s ease-in-out;
  background: #39ff14;
  border: none;
  border-radius: 10px;
  box-shadow: 0 0 10px #39ff14, /* Glowing effect */
                 0 0 20px #39ff14, /* Glowing effect */
                 0 0 40px #39ff14, /* Glowing effect */
                 0 0 60px #39ff14, /* Glowing effect */
                 0 0 80px #39ff14; /* Glowing effect */
    text-shadow: 0 0 5px #39ff14, /* Glowing effect */
                 0 0 10px #39ff14; /* Glowing effect */

}
.teammember h3{
  letter-spacing: 1.5px;
  text-align: center;
  font-size: 22px;
  margin-top: 13px;
  color:	white;
  border: white;
  border-style: solid;
  border-width: 2px;
  padding: 20px;

}

/* NFT collection */
.row{
  display: flex;
  flex-direction: row;
}
.gif{
  height:67vh;
  width:auto;

}
#collection{
  width: 35vw;
  padding-bottom:15px;
  padding-top: 30px;
}
#desc{
  width: 65vw;
  background-color: white;
  opacity: 90%;
}
#desc h2{
  font-family:'druk';
  font-weight: 900;
  font-size: 48px;
  justify-content: center;
  text-transform: uppercase;
  padding:20px;
  color:	#333333;
}
#desc h3{
  font-size: 20px;
  justify-content: center;
  color:	#333333;
  padding:20px
}
.blue{
  color:#2E2EFF;
}
.red{
  color:red;
  font-size: 23px;

}
.underline{
  text-decoration: underline;
  font-size: 23px;
  color:black;
}
.orange{
  color:orange;
  font-size: 23px;
}
.pink{
  color:magenta;
  font-size: 23px;
}
.lb{
  color:#2E2EFF;
  font-size: 23px;
}
.purple{
  color:lightgreen;
  font-size: 23px;
}
.btn3{
  height: 70px;
  width: 300px;
  padding: 15px 25px;
  font-weight: 700;
  font-size: 18px;
  line-height: 120%;
  background: linear-gradient(-210deg,#2E2EFF,#f729e7,#ffd400);
  background-size: 400%!important;
  -webkit-animation: Gradient 800ms ease infinite!important;
  animation: Gradient 800ms ease infinite!important;
  border: none;
  border-radius: 30px;
  letter-spacing: 1.5px;
}
@keyframes Gradient { 
  0%{background-position:10% 0%}
  50%{background-position:91% 100%}
  100%{background-position:10% 0%}
}
#part2{
  height:45vh;
  padding: 60px;
}
#part2 h3{
  font-size: 25px;
  color: #2E2EFF;
  font-family: druk;
  text-transform: uppercase;
}
#part2 h4{
  font-size: 23px;
  padding: 30px;
  line-height: 40px;
  color:black;
}
.boxes{
  background-size: cover;
}
.nft{
  background-image: url('./images/green.jpg');
  background-size: cover;
  background-repeat: no-repeat;
 
}

.box{
  height: 40vh;
  width:30%;
  border: 18px solid #2E2EFF;
  background-color: black;
  border-radius: 7%;
  opacity: 92%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.box:hover{
  background: linear-gradient(-210deg,#2E2EFF,#f729e7,#ffd400);
}
.box:hover h1{
  color: black;
}

.box h1{
  margin-top: 0px!important;
  font-size: 30px;
}

#benefits{
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
}
.row2{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 50px;
  padding-top: 50px;
}

.nfts img{
  height: 150px;
  width:150px;
}
#mission h2{
  font-family: 'League Spartan';
  text-transform: uppercase;
  font-size: 20px;
  line-height: 30px;
  color:white;
  background-color:#262626;
  /* -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: white;;
  -webkit-text-fill-color: transparent; */
  box-shadow: 0 0 10px #39ff14, 
                 0 0 20px #39ff14, 
                 0 0 40px #39ff14, 
                 0 0 60px #39ff14, 
                 0 0 80px #39ff14; 
    text-shadow: 0 0 5px #39ff14, 
                 0 0 10px #39ff14; 
  text-shadow: 2px 2px 8px #000000;
  /* border: white;;
  border-style: solid;
  border-width: 2px; */
  border-radius: 10px;
  padding:50px;
}
#mission {
  min-height: calc(90vh - 160px)!important;
}



.section form{
  display: flex;
  flex-direction: column;
  justify-content: center!important;
  align-items: center;
  background-color:#333333;
  width: 50vw;
  color: white;
  padding: 8px;
  height: 54vh;
}
input{
  width:25vw;
  height: 40px;
  font-size: 20px;
  margin-bottom: 10px;
}

.submissions{
  width: 100vw;
  min-height: calc(110vh - 160px)!important;
}
.submissions ul {
  list-style-type: none;
}
.submissions li{
  font-size: 15px;
  text-align: left;
  font-family: 'League Spartan';
  color: white;
  letter-spacing: 1px;
  padding-bottom: 15px;
}
.submissions h5{
  font-size: 18px;
  font-family: 'League Spartan';
  color: #333333;
  padding-left: 100px;
  padding-right: 100px;
  padding-top: 15px;
  
}
.formimg{
  width: 45vw;
  height:auto;
}
.formimg img{
  width: 350px;
}
#vids h2{
  font-size: 30px;
  margin-top: 1px;
  margin-bottom: 25px;
}
.subscribebtn{
  background-color: red;
  color: white;
  padding: 20px;
  border: none;
  border-radius: 10px!important;
  font-size: 20px;
  text-transform: uppercase;
  margin-bottom: 15px;
}
#vids img{
  height: 70px;
}
.shoutout{
  display: flex;
  flex-direction: column;
}
#vids a{
  text-decoration: none;
  color: black;
}





@media (max-width: 1200px) {
  .App-logo {
    height: 300px;
    pointer-events: none;
  }
  .nav {
    display: flex;
    align-items: center;
    padding: 0px 0px;
    height: 70px;
    top:0;
    position: fixed;
    width: 100vw;
  }
  .logo {
    width: 45px;
    height: 45px;
  }
  .nav-options {
    display: flex;
    width: 100%;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 0;
    transition: all 0.5s ease;
    flex-direction: column;
    list-style-type: none;
    grid-gap: 0px;
  }
  .nav-options.active {
    background: black;
    left: 0;
    opacity: 100%;
    transition: all 0.5s ease;
    z-index: 1;
    align-content: center;
    padding-left: 0px;
    height: 100vh;
  }
  
  .icon {
    width: 30px;
    height: 20px;
    margin-left:50px;

  }

  .mobile-menu {
    display: block;
  
  }
  .logo-nav {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  
  }
  .logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .navlogo{
    height: 65px;
    width: 65px;
    margin:10px;
    margin-right: 200px;
  }
  
  .option {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 25px 17px;
    
  }
  li a{
    color:white;
    text-decoration: none;
    font-family: 'League Spartan';
    font-weight: 800;
  
  }.nav-options

  .section h1{
    background-color: black;
    text-align: center;
    font-size: 22px;
  }
  .video{
    width:100vw;
  }
  .half-mobile-section{
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    height: 60vh
  }
  .col-section{
    margin-top: 25px;
  }
  .col-section ul li img{
    height: 20px;
    width: 20px;
  }

  .part2{
    height:auto;
  }
  .part2 h3{
    font-size: 18px;
  }
  .part2 h4{
    font-size: 18px;
  }
  .home{
    z-index: 99;
  }
  .home h1 {
    font-family: 'League Spartan';
    font-weight: 500;
    font-style: bold;
    letter-spacing: .02em;
    font-size: 20px;
    text-align: center;
  }
  .home img{
    width: auto;
    height: 40vh;
    object-fit: cover;
  
  }
  .row-section h4{
    font-size: 11px!important;
  }
  .row2{
    display: inline-block;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .halfimg img{
    width: 50vw;
    height: auto;
  }
  .part2 h4 {

    padding: 20px;
}
  #mission h2{
    font-size: 12px;
    line-height: 30px;
    padding: 10px;
    height: 100%
  }
  .col-2{
    width: 100%;
  }
  nav{
    background: white;
  }
  .col-section h4 {
    font-size: 18px;
    width: 100%;
    text-align: center;
  }
  .col-section ul li {
    font-size: 15px;
    text-align: left;
    padding: 10px;
    margin-bottom: 0px;
  }
  
  .row{
    flex-direction: column;
    padding-top: 0px;
  }
  #abtphantom{
    font-size: 15px;
    width:auto
  }
  #phantomimg{
    width:auto
  }
  #phantomimg img{
    width:50vw;
    height: auto;
  }
  .part5 .words{
    font-size: 15px;
    padding: 0px;
    margin-top: 10px;
    margin-bottom: 10px;

  }
  .part5{
    height:auto;
    padding:0px;
  }
  .part5 .la img{
    width: 100vw;
  }

  .part5 .studio img{
    
    width: 100vw;
  }
  #copyright p{
    font-size: 15px;
    color:#39ff14
  }
  .enterbtn{
    width: auto;
    margin-right: 0px;
  }
  .term{
    text-align: left;
    height: auto;
    padding: 15px
  }
  .section h1{
    padding-left: 0px;
  }
  .term h3{
    font-size: 16px;
  }
  /* NFT collection mobile */
  #desc h2{
    font-size: 16px;
    line-height: 30px;
  }
  #desc h3{
    font-size: 14px;
    padding:11px;
  }
  .red{
    font-size: 14px;
  }
  .orange{
    font-size: 14px;
  }
  .pink{
    font-size: 14px;
  }
  .green{
    font-size: 14px;
  }
  .lb{
    font-size: 14px;
  }
  #desc{
    width:auto;
    margin:20px
  }
  #access{
    padding: 10px;
    margin-left: 0px;
    margin-right: 0px;
    font-size: 20px;
  }
  .section h1{
    margin-top: 20px;
  }
  .col-section span {
    border-bottom: none;
}
  .box{
    width: 85vw;
    margin-bottom: 20px;
    height: 40vh;
  }

  .box h1{
    font-size: 30px;
    letter-spacing: .9px;
  }
  .gif{
    width: 90vw;
    height: auto;
  }
  #collection{
    width:auto;
    padding-bottom: 0px;
  }

  .btn3{
    height: 48px;
    font-size: 15px;
    width:75vw;
  }
  /* song submissions mobile */
  .formimg {
    width: 100vw;
  }
  .formimg img{
    width: 80vw;
  }
  .section form{
    width: 100vw;
    height:auto
  }
  input{
    width: 80%;
  }
  .submissions h5{
    padding-left: 0px; 
    padding-right: 0px;
    font-size: 14px;
    padding: 2px;
    padding-top: 10px;
  }

  /* meet the team mobile */
  .teammember{
    flex-direction: column;
    width: 80vw;
    height: auto;
  }
  .teammember .image{
    width:90vw;
  }
  .teammember img{
    width: 70vw;
    height: auto
  }
  .teammember .abt{
    width:90vw;
    padding:10px;
  }
  .teammember h2{
    font-size: 30px;
  }
  .teammember h3{
    margin-top: 20px;
    font-size: 14px;
    padding: 20px;
    border: white;
    letter-spacing: 1.2px;
    text-align: center;
    border-style: solid;
    border-width: 2px;
  }
  #team{
    min-height: calc(300vh - 160px);
  }
  #vids iframe{
    height:auto;
    width: 100vw;
  }
  .subscribebtn{

    font-size: 15px;
    margin-bottom: 15px;
  }
 
}