  .hero-section {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100vh; /* Full viewport height */
    color: #ffffff; /* White text color */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden; /* Prevents video from overflowing */
    padding: 100px 20px; 
  }
  .hero-section2 {
    margin-top: 20px;
    /* height: 100%; */
    flex-direction: column;
    color: #ffffff; /* White text color */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 20px;
  }
  .hero-section video {
    display: block; 
  }

  .background-video {
    position: absolute;
    display: block; 
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -100; /* Keep the video behind other elements */
  }

  /* Styling for the text */
.hero-text {
  font-size: 3rem; /* Starting font size */
  line-height: 1.2;
  margin: 0 auto;
  max-width: 2000px;
}

/* Smaller text on smaller screens */
@media (max-width: 850px) {
  .hero-text {
    font-size: 1.5rem; /* Smaller font size for smaller screens */
  }
}

/* Even smaller text for very small screens */
@media (max-width: 480px) {
  .hero-text {
    font-size: 1rem; /* Even smaller font size for very small screens */
  }
}

/* Logo styling, assuming you have a logo in the section */
.hero-logo {
  width: 400px; /* Fixed width for the logo */
  height: auto; /* Maintain aspect ratio */
  margin: 0 auto; 
}

/* Responsive logo size */
@media (max-width: 768px) {
  .hero-logo {
    width: 300px; /* Smaller logo for smaller screens */
  }
  .hero-section h2 {
    -webkit-text-stroke-width: 1px;
    /* font-size: 5rem; */
  }
}

/* Even smaller logo for very small screens */
@media (max-width: 480px) {
  .hero-logo {
    width: 250px; /* Even smaller logo for very small screens */
  }
}

  .hero-section .overlay {
    position: relative;
    text-align: center;
    /* background: rgba(0, 0, 0, 0.5); Semi-transparent overlay */
    padding: 20px;
    z-index: 1; /* Ensure overlay is above the video */
  }

  .hero-section h2 {
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: lightgreen;
    -webkit-text-fill-color: transparent;
    /* font-size: 5rem; */
  }

  

  .hero-section2 h3 {
    font-size: 3.5vw!important;
    text-transform: uppercase;
  }

  @media (min-width: 1200px) {
    .hero-section2 h3 {
      font-size: 2.5vw!important;
      text-transform: uppercase;
    }
  }
  
  .hero-section p {
    font-size: 1.5rem; /* Smaller font size for the paragraph */
    margin-bottom: 40px; /* Space below the paragraph */
  }

  .neonButton {
    margin-top: 20px;
    padding: 10px 30px;
    font-size: 1.5rem;
    color: black;
    background: #39ff14; /* This is a bright green color; adjust as needed for neon effect */
    border: none;
    border-radius: 50px; /* Gives the pill shape */
    box-shadow: 0 0 10px #39ff14, /* Glowing effect */
                 0 0 20px #39ff14, /* Glowing effect */
                 0 0 40px #39ff14, /* Glowing effect */
                 0 0 60px #39ff14, /* Glowing effect */
                 0 0 80px #39ff14; /* Glowing effect */
    text-shadow: 0 0 5px #39ff14, /* Glowing effect */
                 0 0 10px #39ff14; /* Glowing effect */
    transition: all 0.2s ease-in-out;
  }
  
  .neonButton:hover {
    box-shadow: 0 0 20px #39ff14, /* Glowing effect */
                 0 0 30px #39ff14, /* Glowing effect */
                 0 0 50px #39ff14, /* Glowing effect */
                 0 0 70px #39ff14; /* Glowing effect */
  }
  
  .neonButton:focus {
    outline: none;
  }

  