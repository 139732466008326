/* Main Services Styles */
#main-services {
    background-color:black; /* Dark background for contrast */
    color: white; /* White text for readability */
    text-align: center; /* Center the text */
    padding: 50px 0; /* Padding for spacing */
  }
  @media (min-width: 1200px) {
    #main-services {
      height: 100vh;
    }
  }

  .services-row {
    display: flex;
    justify-content: space-around; /* Distribute space evenly around the items */
   /* Margin between the rows */
  }
  
  @media (max-width: 768px) {
    .services-row {
      flex-direction: column;
      align-items: center;
    }
  
    .service {
      width: 80%; /* Adjust width for smaller screens */
      margin-bottom: 20px; /* Space between services on small screens */
    }
  }

  .services-image {
    width: 100%; /* Full width of the container */
    max-width: 600px; /* Maximum width, adjust as needed */
    height: auto; /* Maintain aspect ratio */
    margin: 20px auto; /* Vertical margin and center horizontally */
    display: block; /* To apply margin auto for centering */
  }
  
  .services-heading h1 {
    font-size: 2.5rem; /* Large font size for heading */
    margin-bottom: 0.5rem; /* Small margin for spacing */
    margin-top: 5rem;
  }
  
  .services-heading p {
    font-size: 1.2rem; /* Smaller font size for subheading */
    color: black; /* Lighter text color for differentiation */
  }
  
  .services-offered {
    display: flex; /* Flexbox for service cards */
    justify-content: center; /* Center the cards horizontally */
    gap: 20px; /* Space between cards */
  }
  
  .service {
    width: 80%; /* Equal width for all cards */
    padding: 30px; /* Padding inside cards */
    background-color: #262626; /* Slightly lighter card background */
    border-radius: 8px; /* Rounded corners for cards */
    margin: 20px;
  }
  
  .service img {
    width: 50px; /* Icon size */
    height: auto; /* Maintain aspect ratio */
    margin-bottom: 10px; /* Space below icon */
  }
  
  .service h2 {
    font-size: 1.5rem; /* Heading size for services */
    color: white; /* Pink color for headings to match the theme */
    /* color: #FFC0CB; Pink color for headings to match the theme */
    margin-bottom: 0.5rem; /* Space below heading */
  }
  
  .service p {
    font-size: 1rem; /* Paragraph text size */
    color: #D0D0D0; /* Slightly lighter text color for readability */
  }
  