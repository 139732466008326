body {
    margin: 0;
    padding: 0;
    background-color: #111; /* Dark background */
    color: #fff;
  }
  
  .event-page {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
  }

  /* General styling for the event page */
.event-page {
    padding: 20px;
  }
  
  /* Remove list styles and align text for prizes and judging criteria */
  .prizes .prize-text,
  .judging-criteria .criteria-text {
    padding-left: 20px; /* Optional: Add padding for better alignment */
    margin: 10px 0;
  }
  
  .prizes .prize-text p,
  .judging-criteria .criteria-text p {
    margin: 5px 0;
  }
  

  .event-page ul {
    list-style-position: inside;
    margin: 0 auto; /* Centers the content */
    padding: 0;
    text-align: center;
    max-width: 80%; /* Optional: control list width */
  }
  
  .event-page li {
    margin: 5px 0;
  }
  
  .event-page h1, h2, h3 {
    color: #fff;
    text-shadow: 0 0 5px #0f0, 0 0 10px #0f0, 0 0 20px #0f0; /* Neon green glow */
  }
  
  .nxtup-section {
    margin-bottom: 40px;
    padding: 20px;
    border-radius: 10px;
    background-color: #222; /* Dark container background */
    box-shadow: 0 0 15px rgba(0, 255, 0, 0.3); /* Neon green glow effect */
    border: 2px solid rgba(0, 255, 0, 0.5);
  }
  
  .event-page p, ul, li {
    color: #ccc;
  }
  
  .event-page ul {
    text-align: left;
    margin-left: 20px;
  }
  
  .event-page a {
    color: #0f0; /* Neon green link color */
    text-decoration: none;
    transition: color 0.3s;
  }
  
  .event-page a:hover {
    color: #fff;
    text-shadow: 0 0 5px #0f0, 0 0 10px #0f0;
  }
  
  .event-details, .competition-details, .prizes, .judging-criteria {
    border-top: 4px solid rgba(0, 255, 0, 0.5);
    margin-top: 20px;
  }
  
  .event-page h2 {
    margin-bottom: 10px;
  }
  
  .event-page li {
    margin-bottom: 5px;
  }
  
  .event-page h1 {
    margin-bottom: 20px;
  }
  